import * as React from 'react';
import { Box } from '@material-ui/core';
import SEO from '../components/atoms/SEO';
import GenericPage from '../components/layout/CommonLayout/GenericPage';
import useAnalyticsSessionData from '../hooks/useAnalyticsSessionData';
import {
  EmailListing,
  KeywordRankings,
  MarketAnalytics,
  PopularProperties,
  CustomerAnalytics,
  FAQListing,
  ListingButton,
} from '../components/pages/listwithus';
import useAuthToken from '../store/auth/hooks/useAuthToken';
import useMyUserDetails from '../api/auth/me/useMyUserDetails';

const ListWithUsPage = () => {
  useAnalyticsSessionData();

  // TODO: remove when the feature rolls out
  const authToken = useAuthToken();
  const { data: myUserDetails } = useMyUserDetails({
    authToken,
  });

  return (
    <GenericPage>
      <SEO title="List with Us" />

      {myUserDetails && (
        <Box>
          <EmailListing />
          <KeywordRankings />
          <MarketAnalytics />
          <PopularProperties />
          <CustomerAnalytics />
          <FAQListing />
          <ListingButton />
        </Box>
      )}
    </GenericPage>
  );
};

export default ListWithUsPage;
