import * as React from 'react';
import { Box, Grid } from '@material-ui/core';
import useStyles from './KeywordRankings.styles';
import { Link, Typography } from '../../../atoms';
import { getRangeArray } from '../../../../utils/array';

export default function KeywordRankings() {
  const classes = useStyles();

  return (
    <Box className={classes.bgCtn}>
      <Box className={classes.ctn}>
        <Typography
          fontSize={22}
          fontWeight={600}
          color="gray"
          colorVariant="contrastText"
          textAlign="center"
          className={classes.title}
        >
          Keyword Search Rankings
        </Typography>
        <Grid className={classes.cardCtn} container>
          {topKeywords.map(({ keyword, top }) => (
            <Grid className={classes.cardItem} key={keyword} item xs={6} lg={3}>
              <Box className={classes.card}>
                <Box className={classes.cardTitle}>
                  <Typography
                    color="primary"
                    component="span"
                    className={classes.topText}
                  >
                    Top
                  </Typography>
                  <Typography
                    color="primary"
                    component="span"
                    className={classes.topNumber}
                  >
                    {top}
                  </Typography>
                </Box>
                <Typography
                  color="gray"
                  colorVariant="contrastText"
                  className={classes.keyword}
                >
                  “{keyword}”
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box className={classes.benefits} px={2}>
          {rankingBenefits.map(({ index, description, title }) => (
            <Box key={index} className={classes.benefit}>
              <Box className={classes.img}>
                <img
                  src={`/images/listwithus/keyword-${index}.png`}
                  alt="Keywords"
                />
              </Box>
              <Box className={classes.ranking}>
                <Typography
                  color="gray"
                  colorVariant="light1"
                  className={classes.rankingIndex}
                >
                  {('0' + index.toString()).slice(-2)}
                </Typography>
                <Box className={classes.rankingCard}>
                  <Typography
                    color="gray"
                    colorVariant="contrastText"
                    className={classes.rankingTitle}
                  >
                    {title}
                  </Typography>
                  <Typography
                    color="gray"
                    colorVariant="contrastText"
                    className={classes.rankingDesc}
                  >
                    {description}
                  </Typography>
                  {index === 2 && (
                    <Grid className={classes.logoCtn} container spacing={2}>
                      {getRangeArray(1, 6).map((idx) => (
                        <Grid className={classes.logo} item key={idx} xs={4}>
                          <Link to="#">
                            <img
                              src={`/images/listwithus/landlord-${idx}-logo.png`}
                              alt="Landlord Logo"
                            />
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

const topKeywords = [
  {
    top: 6,
    keyword: 'build to rent',
  },
  {
    top: 1,
    keyword: 'build to rent in London',
  },
  {
    top: 1,
    keyword: 'build to rent in Manchester',
  },
  {
    top: 1,
    keyword: 'build to rent in Birmingham',
  },
];

const rankingBenefits = [
  {
    index: 1,
    title: 'Reach new tenants and grow your rental portfolio',
    description:
      'Create new leads with prospective tenants across the UK and around the globe.',
  },
  {
    index: 2,
    title: 'Improve brand awareness and reputation',
    description:
      'Join 170 institutional landlords fully trusted by tenants and boost your landlord profile.',
  },
  {
    index: 3,
    title: 'Access customer analytics to understand their behaviours',
    description:
      'See what customers are looking for to perfect your listings and future developments.',
  },
];
