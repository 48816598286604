import * as React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './FAQListing.styles';
import { Typography } from '../../../atoms';
import { ExpansionPanel } from '../../../organisms';

export default function FAQListing() {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      <Typography
        color="gray"
        colorVariant="contrastText"
        className={classes.title}
      >
        You may be wondering…
      </Typography>
      <Box className={classes.faqsCtn}>
        {faqs.map((faq, j) => (
          <ExpansionPanel key={j} headerText={faq.title}>
            <Typography>{faq.value}</Typography>
          </ExpansionPanel>
        ))}
      </Box>
    </Box>
  );
}

const faqs = [
  {
    title: 'What are Build To Rent properties?',
    value: (
      <>
        Build To Rent is a new type of rental properties that is built, owned
        and managed by institutional landlords and property managers. This is
        different from traditional private letting where an individual landlord
        owns the property and manages it himself, or instructs an agent to
        manage it.
      </>
    ),
  },
  {
    title:
      'How are Build To Rent properties different from traditional private lets?',
    value: (
      <>
        Housing quality and scale of operations are the key differences. Most
        Build To Rent properties were built in the last 2-4 years following
        strict quality standards and are regularly maintained as part of the
        whole development. A private-let property could be ex-council and much
        older with maintenance subject to landlords&#39; budget.
        <br />
        <br />
        When something goes wrong in a Build To Rent property, onsite management
        has readily available tools and resources for immediate fix. A private
        landlord can be slower and less responsive.
      </>
    ),
  },
  {
    title: 'What is the Build To Rent company?',
    value: (
      <>
        We are a community edited database of Build to Rent and co-living
        developments the UK. We do not let or manage property, instead we
        aggregate public data of the developments and list their managers along
        with links to their websites and direct contact details. Our
        content-rich, data-driven platform helps tenants research their next
        rental homes in depth and connect with landlords directly. Customer
        feedbacks create invaluable insights for prospective tenants and allow
        landlords to tailor their future developments to best fit upcoming
        market trends.
      </>
    ),
  },
  {
    title: 'What is your database about?',
    value: (
      <>
        Our database lists all Build to Rent and co-living developments in the
        UK. Anyone can add new developments to the database. We collect all
        kinds of information about existing buildings in the UK - photos,
        videos, details, stories, area guides, customer insights and much more.
      </>
    ),
  },
  {
    title: 'What type of developments are in your database?',
    value: (
      <>Those that were built specifically for residential letting purposes.</>
    ),
  },
];
