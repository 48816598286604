import * as React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './ListingButton.styles';
import { Button, Typography } from '../../../atoms';

export default function ListingButton() {
  const classes = useStyles();

  return (
    <Box className={classes.bgCtn}>
      <Box className={classes.ctn}>
        <Typography
          color="gray"
          colorVariant="contrastText"
          className={classes.title}
        >
          Ready to grow your rental business?
        </Typography>
        <Button
          className={classes.btn}
          color="primary"
          variant="contained"
          fullWidth
        >
          Start listing
        </Button>
      </Box>
    </Box>
  );
}
