import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    bgCtn: {
      display: 'flex',
      background: 'url(/images/listwithus/england.png) top no-repeat',
      backgroundSize: 'contain',
    },
    ctn: {
      maxWidth: theme.custom.container1460,
      padding: theme.spacing(3.75, 0),
      margin: 'auto',
      flex: 1,
    },
    title: {
      fontSize: 22,
      fontWeight: 600,
    },
    cardCtn: {
      padding: theme.spacing(3),
    },
    cardItem: {
      padding: theme.spacing(0.5),
      aspectRatio: '1 / 1',
    },
    card: {
      backgroundColor: '#FCFCFC',
      borderRadius: theme.spacing(2),
      padding: theme.spacing(4),
      minHeight: 140,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: '0px 4px 10px 0px #bcbcbc33',
      height: '100%',
      justifyContent: 'center',
    },
    cardTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(0.5),
    },
    topText: {
      textAlign: 'center',
      fontWeight: 600,
    },
    topNumber: {
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 29,
    },
    keyword: {
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 12,
    },
    benefits: {
      padding: theme.spacing(0, 2),
      display: 'flex',
      flexDirection: 'column',
    },
    benefit: {
      display: 'flex',
      gap: theme.spacing(3),
      marginBottom: theme.spacing(2.5),
      flexDirection: 'column',

      '&:nth-child(n+2) > $img': {
        display: 'none',
      },

      '& > p': {
        width: 25,
        minWidth: 25,
      },

      '& > div': {
        display: 'flex',
        gap: theme.spacing(1),
      },
    },
    img: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(2.5),
      flex: 1,
      '& > img': {
        objectPosition: 'center',
        objectFit: 'cover',
      },
    },
    ranking: {
      display: 'flex',
      flex: 1,
    },
    rankingIndex: {
      fontSize: 24,
      fontWeight: 600,
    },
    rankingTitle: {
      fontSize: 20,
      fontWeight: 600,
    },
    rankingDesc: {
      fontSize: 14,
    },
    logoCtn: {
      display: 'none',
      marginTop: theme.spacing(2),
    },
    logo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    [theme.breakpoints.up('md')]: {
      bgCtn: {
        backgroundPositionY: '-25%',
      },
      ctn: {
        padding: theme.spacing(15, 0),
      },
      title: {
        fontSize: 48,
        marginBottom: theme.spacing(11),
      },
      cardCtn: {
        marginBottom: 280,
      },
      cardItem: {
        padding: theme.spacing(1.25),
      },
      cardTitle: {
        gap: theme.spacing(1.5),
      },
      topText: {
        fontSize: 36,
      },
      topNumber: {
        fontSize: 90,
      },
      keyword: {
        fontSize: 24,
      },
      rankingIndex: {
        display: 'none',
      },
      rankingCard: {
        maxWidth: 555,
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
      },
      rankingTitle: {
        fontSize: 48,
      },
      rankingDesc: {
        fontSize: 24,
      },
      benefits: {
        gap: theme.spacing(18.5),
      },
      benefit: {
        flexDirection: 'row',
        gap: 0,

        '&:nth-child(2n+1)': {
          flexDirection: 'row-reverse',
        },

        '&:nth-child(2n) > $ranking': {
          justifyContent: 'flex-end',
        },

        '&:nth-child(n+2) > $img': {
          display: 'flex',
        },
      },
      img: {
        '& > img': {
          width: '100%',
        },
      },
      logoCtn: {
        display: 'flex',
      },
      logo: {
        '& img': {
          maxHeight: 150,
        },
      },
    },
  })
);
