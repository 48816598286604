import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    bgCtn: {
      backgroundColor: '#F7F7F7',
    },
    ctn: {
      padding: theme.spacing(5, 0, 7, 0),
      margin: 'auto',
    },
    title: {
      maxWidth: 200,
      margin: 'auto',
      marginBottom: theme.spacing(2),
      fontSize: 22,
      fontWeight: 600,
      textAlign: 'center',
    },
    sliderBg: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      height: 240,
    },
    sliderInfo: {
      marginTop: 'auto',
      background: 'white',
      padding: theme.spacing(3, 4, 2, 4),
    },
    slickCtn: {
      '& .slick-dots li button:before': {
        fontSize: 10,
      },
    },
    sliderItem: {
      padding: theme.spacing(0, 4),
    },
    slickBtn: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),
      display: 'none',
      cursor: 'pointer',
      position: 'absolute',
      top: 'calc(50% - 10px)',
      borderRadius: '50%',
      backgroundColor: 'white',
      width: 50,
      height: 50,
      boxShadow: '0 2px 2px rgb(0 0 0 / 16%)',
      zIndex: 1,
      backgroundSize: 'cover',
      '&.slick-disabled': {
        opacity: 0.5,
        cursor: 'unset',
      },
    },
    slickPrevBtn: {
      left: -10,
      transform: 'scaleX(-1) translateY(-50%)',
    },
    slickNextBtn: {
      right: -10,
      transform: 'translateY(-50%)',
    },
    sliderTitle: {
      fontSize: 18,
      fontWeight: 600,
    },
    sliderDescription: {
      fontSize: 15,
    },
    [theme.breakpoints.up('md')]: {
      ctn: {
        padding: theme.spacing(16, 0, 17.5, 0),
      },
      title: {
        fontSize: 48,
        maxWidth: 'initial',
        marginBottom: theme.spacing(11),
      },
    },
    [theme.breakpoints.up('lg')]: {
      slickCtn: {
        '& .slick-list': {},

        '& .slick-slide': {
          flex: 1,

          '& > div': {
            display: 'flex',
          },
        },

        '& .slick-center': {
          zIndex: 1,
          transform: 'scale(1.12)',
          transition: 'all .75s ease',
          '& $sliderItem': {
            opacity: 1,
          },
          '& $sliderBg': {
            boxShadow: '0 10px 10px rgb(204 204 204 / 16%)',
          },
          '& $sliderInfo': {
            display: 'flex',
          },
          '& $slickBtn': {
            display: 'flex',
          },
        },
      },
      sliderItem: {
        padding: theme.spacing(5, 2),
        width: '660px !important',
        height: 515,
        opacity: 0.5,
        margin: 'auto',
        position: 'relative',
      },
      sliderBg: {
        height: '100%',
      },
      sliderInfo: {
        display: 'none',
        textAlign: 'center',
        background: 'unset',
        backgroundImage: 'linear-gradient(transparent, #ffffff80, #ffffff 70%)',
        height: '70%',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'column',
        paddingBottom: '2rem',
      },
      sliderTitle: {
        fontSize: 24,
      },
      sliderDescription: {
        fontSize: 18,
      },
    },
  })
);
