import * as React from 'react';
import { Box, Hidden, useTheme } from '@material-ui/core';
import useStyles from './CustomerAnalytics.styles';
import { Typography } from '../../../atoms';
import Slider, { Settings as SliderSettings } from 'react-slick';
import { KeyboardArrowRight } from '@material-ui/icons';
import clsx from 'clsx';

export default function CustomerAnalytics() {
  const classes = useStyles();
  const theme = useTheme();
  const sliderRef = React.useRef<Slider>(null);

  const sliderSettings: SliderSettings = {
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    slidesToShow: 1,
    arrows: false,
    className: classes.slickCtn,
    centerMode: true,
    centerPadding: '500px',
    variableWidth: true,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 1,
          centerPadding: '0',
          arrows: false,
          infinite: false,
          centerMode: false,
          dots: true,
          adaptiveHeight: false,
          variableWidth: false,
        },
      },
    ],
  };

  const onClickNext = () => {
    sliderRef.current?.slickNext();
  };

  const onClickPrev = () => {
    sliderRef.current?.slickPrev();
  };

  return (
    <Box className={classes.bgCtn}>
      <Box className={classes.ctn}>
        <Typography
          color="gray"
          colorVariant="contrastText"
          className={classes.title}
        >
          Customer analytics, all in one suite
        </Typography>
        <Slider {...sliderSettings} ref={sliderRef}>
          {customerAnalyticData.map(({ description, thumbnail, title }) => (
            <Box className={classes.sliderItem} key={title}>
              <Hidden mdDown>
                <CarouselBtn type="prev" onClick={onClickPrev} />
              </Hidden>
              <Box
                className={classes.sliderBg}
                style={{
                  background: `url(/images/listwithus/${thumbnail}) #f9f9f9 center / contain no-repeat`,
                }}
              >
                <Box className={classes.sliderInfo}>
                  <Typography color="primary" className={classes.sliderTitle}>
                    {title}
                  </Typography>
                  <Typography className={classes.sliderDescription}>
                    {description}
                  </Typography>
                </Box>
              </Box>
              <Hidden mdDown>
                <CarouselBtn type="next" onClick={onClickNext} />
              </Hidden>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
}

const CarouselBtn = ({
  type,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { type: 'next' | 'prev' }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        type === 'next' && classes.slickNextBtn,
        type === 'prev' && classes.slickPrevBtn,
        classes.slickBtn
      )}
      onClick={props.onClick}
    >
      <KeyboardArrowRight fontSize="large" />
    </div>
  );
};

const customerAnalyticData = [
  {
    title: 'Built-in analytics',
    description: 'Learn insights about how your portfolio is doing',
    thumbnail: 'customer-analytics-1.png',
  },
  {
    title: 'Centralised platform',
    description: 'Top down analysis of all developments in one place',
    thumbnail: 'customer-analytics-2.png',
  },
  {
    title: 'Benchmarking',
    description: 'Learn from other peers to improve your performance',
    thumbnail: 'customer-analytics-3.png',
  },
  {
    title: 'Effective communication',
    description: 'Top down analysis of all developments in one place',
    thumbnail: 'customer-analytics-4.png',
  },
];
