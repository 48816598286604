import * as React from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';
import useStyles from './PopularProperties.styles';
import { Link, Typography } from '../../../atoms';
import { getRangeArray } from '../../../../utils/array';
import { PopularPropertiesTabs } from '../../../widgets';

const CITIES = [
  'London',
  'Manchester',
  'Sheffield',
  'Birmingham',
  'Liverpool',
  'Leeds',
  'Oldham',
];

export default function PopularProperties() {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      <Typography
        fontSize={22}
        fontWeight={600}
        color="gray"
        colorVariant="contrastText"
        textAlign="center"
        className={classes.title}
      >
        Grow, no matter where you are
      </Typography>
      <Box className={classes.tabs}>
        <PopularPropertiesTabs
          cities={CITIES}
          showViewAllPropertiesButton={false}
          rows={1}
          analytics
        />
      </Box>
      <Grid container spacing={2}>
        <Hidden mdUp>
          {getRangeArray(1, 3).map((idx) => (
            <Grid className={classes.logo} item key={idx} xs={4}>
              <Link to="#">
                <img
                  src={`/images/listwithus/landlord-${idx}-logo.png`}
                  alt="Landlord Logo"
                />
              </Link>
            </Grid>
          ))}
          {getRangeArray(1, 3).map((idx) => (
            <Grid className={classes.logo} item key={idx} xs={4}>
              <Link to="#">
                <img
                  src={`/images/listwithus/landlord-${idx}-logo.png`}
                  alt="Landlord Logo"
                />
              </Link>
            </Grid>
          ))}
        </Hidden>
        <Hidden mdDown>
          {getRangeArray(1, 4).map((idx) => (
            <Grid className={classes.logo} item key={idx} md={3}>
              <Link to="#">
                <img
                  src={`/images/listwithus/landlord-${idx}-logo.png`}
                  alt="Landlord Logo"
                />
              </Link>
            </Grid>
          ))}
        </Hidden>
      </Grid>
    </Box>
  );
}
