import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    bgCtn: {
      background:
        'url(/images/listwithus/email-listing-cover.png) #040D2100 center no-repeat',
      height: 317,
      backgroundBlendMode: 'multiply',
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    title1: {
      display: 'none',
      fontSize: 18,
      marginBottom: 28,
    },
    title2: {
      fontSize: 26,
      fontWeight: 600,
    },
    title3: {
      fontSize: 14,
      fontWeight: 600,
    },
    ctn: {
      maxWidth: theme.custom.container1460,
      padding: theme.spacing(0, 5),
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'relative',
      margin: 'auto',
    },
    inputRoot: {
      height: 30,
      padding: 0,
      backgroundColor: '#ffffffdb',
      margin: theme.spacing(2, 0),
    },
    input: {
      padding: theme.spacing(0, 1.25),
      fontSize: 14,
    },
    outline: {
      border: 'none',
    },
    button: {
      padding: theme.spacing(0, 1),
      textTransform: 'none',
      height: '100%',
      boxShadow: 'none',
      borderRadius: theme.spacing(0, 0.5, 0.5, 0),
      minWidth: 'fit-content',
    },
    fixedTextFieldCtn: {
      position: 'fixed',
      top: 60,
      left: 0,
      width: '100%',
      padding: theme.spacing(0.5, 6.5),
      backgroundColor: theme.palette.primary.main,
      zIndex: 2,
      display: 'flex',
    },
    fixedTextField: {
      maxWidth: theme.custom.container1460,
      margin: 'auto',
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& > $title3': {
        display: 'none',
      },
      '& > $title2': {
        display: 'none',
        fontSize: 28,
        flex: 1,
        textAlign: 'left',
      },
    },
    text: {
      maxWidth: 670,
    },
    textField: {
      width: '100%',
      flex: 1,
    },
    fixedInputRoot: {
      border: '1px solid #FFFFFF',
    },
    signIn: {
      fontSize: 14,
    },
    [theme.breakpoints.up('md')]: {
      bgCtn: {
        height: 786,
      },
      ctn: {
        paddingBottom: 100,
      },
      title1: {
        display: 'block',
        textAlign: 'center',
      },
      title2: {
        fontSize: 48,
        fontWeight: 600,
        textAlign: 'center',
      },
      title3: {
        fontSize: 24,
        fontWeight: 400,
        textAlign: 'center',
        marginBottom: 80,
      },
      fixedTextFieldCtn: {
        top: 83,
      },
      fixedTextField: {
        '& > $title2': {
          display: 'block',
        },
        '& $inputRoot': {
          height: 53,
          padding: theme.spacing(1, 2),
        },
      },
      inputRoot: {
        height: 62,
        padding: theme.spacing(1.5, 3),
        borderRadius: 0,
      },
      button: {
        borderRadius: theme.spacing(0.5),
        minWidth: 150,
      },
      signIn: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    [theme.breakpoints.up('xl')]: {
      bgCtn: {
        height: 1080,
      },
      ctn: {
        paddingBottom: 150,
      },
    },
  })
);
