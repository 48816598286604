import * as React from 'react';
import { Box, Grid } from '@material-ui/core';
import useStyles from './MarketAnalytics.styles';
import { Typography } from '../../../atoms';
import { formatNumber } from '../../../../utils/number';

export default function MarketAnalytics() {
  const classes = useStyles();

  return (
    <Box className={classes.bgCtn}>
      <Box className={classes.ctn}>
        <Typography
          color="gray"
          colorVariant="contrastText"
          className={classes.title}
        >
          Expand your reach to new markets
        </Typography>
        <Grid className={classes.cardCtn} container>
          {newMarkets.map(({ count, time, title }) => (
            <Grid className={classes.cardItem} key={title} item xs={4}>
              <Box className={classes.card}>
                <Typography
                  color="gray"
                  colorVariant="contrastText"
                  className={classes.cardTitle}
                >
                  {title}
                </Typography>
                <Typography color="primary" className={classes.cardCount}>
                  {formatNumber(count)}
                </Typography>
                <Typography className={classes.cardTime}>{time}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

const newMarkets = [
  {
    title: 'Total',
    count: 10000,
    time: 'Monthly users',
  },
  {
    title: 'UK',
    count: 10000,
    time: 'Monthly users',
  },
  {
    title: 'Overseas',
    count: 10000,
    time: 'Monthly users',
  },
];
