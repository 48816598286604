import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    bgCtn: {
      display: 'flex',
      background:
        'url(/images/listwithus/england.png) #F7F7F7 center no-repeat',
      backgroundSize: 'contain',
    },
    ctn: {
      maxWidth: theme.custom.container1460,
      padding: theme.spacing(6, 0, 11, 0),
      margin: 'auto',
      flex: 1,
    },
    title: {
      maxWidth: 200,
      margin: 'auto',
      marginBottom: theme.spacing(2),
      fontSize: 22,
      fontWeight: 600,
      textAlign: 'center',
    },
    cardCtn: {
      padding: theme.spacing(1),
    },
    cardItem: {
      padding: theme.spacing(0.5),
    },
    card: {
      backgroundColor: '#FCFCFC',
      borderRadius: theme.spacing(2),
      padding: theme.spacing(2),
      minHeight: 110,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: '0px 4px 10px 0px #bcbcbc33',
    },
    cardTitle: {
      fontSize: 14,
      fontWeight: 600,
    },
    cardCount: {
      fontSize: 29,
      fontWeight: 600,
    },
    cardTime: {
      fontSize: 10,
    },

    [theme.breakpoints.up('md')]: {
      ctn: {
        padding: theme.spacing(16.5, 0, 29.5, 0),
      },
      title: {
        fontSize: 48,
        maxWidth: 'initial',
        marginBottom: theme.spacing(20),
      },
      cardCtn: {},
      cardItem: {
        padding: theme.spacing(1.25),
      },
      card: {
        padding: theme.spacing(8.5, 7),
        alignItems: 'flex-start',
      },
      cardTitle: {
        fontSize: 24,
      },
      cardCount: {
        fontSize: 80,
      },
      cardTime: {
        fontSize: 18,
      },
    },
  })
);
