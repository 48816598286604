import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    ctn: {
      maxWidth: theme.custom.container1460,
      padding: theme.spacing(3, 0, 7, 0),
      margin: 'auto',
    },
    title: {
      marginBottom: theme.spacing(2),
      fontSize: 22,
      fontWeight: 600,
      textAlign: 'center',
    },
    faqsCtn: {
      margin: theme.spacing(1.5),
    },

    [theme.breakpoints.up('md')]: {
      ctn: {
        padding: theme.spacing(16.5, 0, 29.5, 0),
      },
      title: {
        fontSize: 48,
        maxWidth: 'initial',
        marginBottom: theme.spacing(9),
      },
    },
  })
);
