import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    bgCtn: {
      backgroundColor: '#F7F7F7',
    },
    ctn: {
      maxWidth: theme.custom.container1460,
      padding: theme.spacing(5),
      margin: 'auto',
    },
    title: {
      marginBottom: theme.spacing(2),
      fontSize: 22,
      fontWeight: 600,
      textAlign: 'center',
    },
    btn: {
      height: 30,
      textTransform: 'none',
    },

    [theme.breakpoints.up('md')]: {
      bgCtn: {
        background:
          'url(/images/listwithus/listing-footer-cover.png) center no-repeat',
        backgroundSize: 'cover',
      },
      ctn: {
        height: 630,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 100,
      },
      title: {
        fontSize: 48,
        marginBottom: theme.spacing(4),
      },
      btn: {
        height: 40,
        width: 'auto',
        padding: theme.spacing(1.25, 7.5),
      },
    },
  })
);
