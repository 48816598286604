import * as React from 'react';
import { Box, TextField } from '@material-ui/core';
import useStyles from './EmailListing.styles';
import { Button, Link, Typography } from '../../../atoms';
import clsx from 'clsx';

const isInViewport = (el: Element | null) => {
  if (el == null) return false;
  const { top, left, bottom, right } = el.getBoundingClientRect();
  return (top >= 0 || bottom >= 0) && (left >= 0 || right >= 0);
};

export default function EmailListing() {
  const classes = useStyles();
  const ctnRef = React.useRef<HTMLInputElement>(null);
  const [isInputFixed, setIsInputFixed] = React.useState(false);

  React.useEffect(() => {
    const onScroll = () => {
      setIsInputFixed(!isInViewport(ctnRef.current));
      isInViewport(ctnRef.current);
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div ref={ctnRef} className={classes.bgCtn}>
      <div className={classes.ctn}>
        <Typography
          color="primary"
          colorVariant="contrastText"
          className={classes.title1}
        >
          List with us
        </Typography>
        <Box className={clsx(isInputFixed && classes.fixedTextFieldCtn)}>
          <Box
            className={clsx(
              isInputFixed ? classes.fixedTextField : classes.text
            )}
          >
            <Typography
              color="primary"
              colorVariant="contrastText"
              className={classes.title2}
            >
              Access 15,000 monthly users with our platform.
            </Typography>
            <Typography
              color="primary"
              colorVariant="contrastText"
              className={classes.title3}
            >
              No listing fees.
            </Typography>
            <TextField
              placeholder="Email address"
              variant="outlined"
              className={classes.textField}
              InputProps={{
                className: clsx(
                  classes.inputRoot,
                  isInputFixed && classes.fixedInputRoot
                ),
                classes: {
                  input: classes.input,
                  notchedOutline: classes.outline,
                },
                endAdornment: (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Start listing
                  </Button>
                ),
              }}
            />
          </Box>
        </Box>
        <Typography
          className={classes.signIn}
          color="primary"
          colorVariant="contrastText"
        >
          Already listed your properties on buildtorent.io?{' '}
          <Link noColor to="#" textDecoration="underline">
            Sign in
          </Link>
        </Typography>
      </div>
    </div>
  );
}
