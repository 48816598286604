import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    ctn: {
      maxWidth: theme.custom.container1460,
      padding: theme.spacing(5, 0, 6, 0),
      margin: 'auto',
    },
    title: {
      maxWidth: 200,
      margin: 'auto',
      marginBottom: theme.spacing(2),
    },
    logo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        maxWidth: 92,
      },
    },
    tabs: {
      marginBottom: theme.spacing(2),
    },

    [theme.breakpoints.up('md')]: {
      ctn: {
        padding: theme.spacing(16.5, 0, 29.5, 0),
      },
      title: {
        fontSize: 48,
        maxWidth: 'initial',
        marginBottom: theme.spacing(7),
      },
      logo: {
        '& img': {
          maxWidth: '100%',
        },
      },
      tabs: {
        marginBottom: theme.spacing(22),
      },
    },
  })
);
